import React, { useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Com from '../../../assets/images/svg/com.svg';
import Tooltip from 'react-bootstrap/Tooltip';
import { ShimmerTitle} from 'react-shimmer-effects';
import DashboardScreen from './Dashboard';
import CashFlow from './CashFlow';
import RecentTransactions from './Accounts';

const Dashboard = (props) => {
    const [key, setKey] = useState('dashboard');
    const [dashboardData, setDashboardData] = useState({});
    const [loading, setLoading] = useState(true);
    const userToken = localStorage.getItem("userToken");
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Current and overdue amount, which you will receive every day or in months.
        </Tooltip>
    );

    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_HOST}dashboard`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${userToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setDashboardData(data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            }
        };

        fetchDashboardData();
    }, []);

    if (loading) {
        return (
            <div className="container-right">
               <ShimmerTitle line={10} gap={30} variant="primary" />
            </div>
        );
    }

    return (
        <div className="container-right">
            <div className='companyheadr'>
                <div className='companydetails'>
                    <div className='icon'>
                        <img src={Com} alt="Company Icon" />
                    </div>
                    <div className='headingname'>
                        <h1>Hello, {dashboardData.orgName}</h1>
                        <p>Smart Accounting & Cashflow Manager</p>
                    </div>
                </div>

         
            </div>


            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="dashboard" title="Dashboard">
                 <DashboardScreen />
                </Tab>
                {/* <Tab eventKey="explore" title="Cash Flow"> 
                <CashFlow />
                
                </Tab> */}
               
               
            </Tabs>
        </div>
    );
};

export default Dashboard;
