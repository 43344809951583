import React, { useState, useEffect } from 'react';
import ApexCharts from 'react-apexcharts';
import { ShimmerTitle} from 'react-shimmer-effects';
import CurrencyFormatter from '../../Utils/CurrencyFormatter';
const DashboardScreen = () => {
    const [dashboardData, setDashboardData] = useState({});
    const [expenses, setExpenses] = useState("");
    const userToken = localStorage.getItem("userToken");
      const [loading, setLoading] = useState(true);
      const currentYear = new Date().getFullYear();
    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_HOST}dashboard`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${userToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();
                setDashboardData(data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            }
        };
        fetchDashboardData();
    }, []);

    useEffect(() => {
        const fetchExpense = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_HOST}expenses-summary`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${userToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();
                setExpenses(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            }
        };
        fetchExpense();
    }, []);

      if (loading) {
            return (
                
                   <ShimmerTitle line={10} gap={30} variant="primary" />
              
            );
        }
    

    const revenueChartOptions = {
        chart: { type: 'line', height: 350, toolbar: { show: false } },
        xaxis: { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] },
        stroke: { width: 2, curve: 'smooth' },
        colors: ['#007bff'],
        tooltip: { theme: 'light' },
        legend: { show: true, position: 'top' },
    };

    const billsChartOptions = { ...revenueChartOptions, colors: ['#28a745'] };

    const revenueData = [
        dashboardData.janRevenue || 0, dashboardData.febRevenue || 0, dashboardData.marRevenue || 0,
        dashboardData.aprRevenue || 0, dashboardData.mayRevenue || 0, dashboardData.junRevenue || 0,
        dashboardData.julRevenue || 0, dashboardData.augRevenue || 0, dashboardData.sepRevenue || 0,
        dashboardData.octRevenue || 0, dashboardData.novRevenue || 0, dashboardData.decRevenue || 0
    ];

    const billsData = [
        dashboardData.janBills || 0, dashboardData.febBills || 0, dashboardData.marBills || 0,
        dashboardData.aprBills || 0, dashboardData.mayBills || 0, dashboardData.junBills || 0,
        dashboardData.julBills || 0, dashboardData.augBills || 0, dashboardData.sepBills || 0,
        dashboardData.octBills || 0, dashboardData.novBills || 0, dashboardData.decBills || 0
    ];

    return (
        <div className='flex-row'>
            <div className='biiling-inshights'>
                 <div className='biiling-inshights'>
                     <div className='bill-inshights-tabs first-tab fff'>
                         <p id="total">Total Revenue ({currentYear})</p>
                         <h2><CurrencyFormatter amount={dashboardData.totalRevenue} /></h2>
                     </div>
                     <div className='bill-inshights-tabs fff'>
                     <p>Bank Balance</p>
                     <h2><CurrencyFormatter amount={dashboardData.bankBalance} /></h2>
                    
                        
                     </div>
                     <div className='bill-inshights-tabs fff'>
                     <p>Monthly Expenses</p>
                   
                     <h2><CurrencyFormatter amount={expenses.monthlyTotalExpenses} /></h2>
                     </div>
                     <div className='bill-inshights-tabs fff'>
                     <p>Active Clients</p>
                     <h2>{dashboardData.totalActiveClients || 0}</h2>
                   
                        
                     </div>
                     <div className='bill-inshights-tabs fff'>
                     <p>Total Bills Created</p>
                     <h2>{dashboardData.totalBillsCreated || 0}</h2>
                     </div>
                 
                 </div>
                 </div>
  
                
            <div className='biiling-inshights'>
                <div className='bill-inshights-tabs first-tab ff'>
                    <p>Revenue Projection</p>
                    <ApexCharts options={revenueChartOptions} series={[{ name: 'Revenue', data: revenueData }]} width="420" height="350" />
                </div>
                <div className='bill-inshights-tabs ff'>
                <p>Billing Data</p>
                    <ApexCharts options={billsChartOptions} series={[{ name: 'Bills', data: billsData }]} width="420" height="350" />
                </div>
            </div>
        </div>
    );
};

export default DashboardScreen;
