import { useEffect, useState } from "react";
import { Table, Dropdown, Menu, Button, Select, DatePicker, message, Skeleton } from "antd";
import { FaFileExcel } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { DownOutlined } from "@ant-design/icons";
import { ShimmerTable } from "react-shimmer-effects";

const { Option } = Select;
const { RangePicker } = DatePicker;

const ShimmerTableN = () => {
  return (
    <ShimmerTable row={5} col={5} /> 
  );
};

const InvoiceList = ({ setShow, user }) => {
  setShow(true);

  const [filter, setFilter] = useState({ filter: "", asc: "no", fromDate: "", toDate: "" });
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [invoicesPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const userToken = localStorage.getItem("userToken");
  const navigate = useNavigate();

  const fetchInvoices = async () => {
    setLoading(true);
    try {
      const config = { headers: { Authorization: `Bearer ${userToken}` } };
      const { data } = await axios.get(
        `${process.env.REACT_APP_HOST}filterinvoices?fromDate=${filter.fromDate}&toDate=${filter.toDate}&filter=${filter.filter}&asc=${filter.asc}`,
        config
      );
      setInvoices(data.invoices || []);
      setTotalPages(Math.ceil((data.invoices?.length || 0) / invoicesPerPage));
    } catch (error) {
     
      setInvoices([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, [filter, currentPage]);

  const columns = [
    { title: "Invoice Date", dataIndex: "createdDate", render: (date) => moment(date).format("DD-MM-YYYY") },
    { title: "Status", dataIndex: "invoiceStatus", render: (clientCycle) => (
      <span id={clientCycle?.toLowerCase()}>{clientCycle}</span>
    ), },
    { title: "Invoice No.", dataIndex: "billNumber" },
    { title: "Customer", dataIndex: "orgName" },
    { title: "Amount", dataIndex: "totalINR", render: (amount) => `₹ ${amount.toFixed(2)}` },
    {
      title: "Manage",
      render: (_, record) => (
        <Dropdown overlay={
          <Menu>
            <Menu.Item onClick={() => navigate(`/invoice/${record._id}/view`)}>View</Menu.Item>
            <Menu.Item onClick={() => navigate(`/invoice/edit/${record._id}`)}>Edit</Menu.Item>
            <Menu.Item onClick={() => console.log("Delete Invoice")}>Delete</Menu.Item>
            <Menu.Item onClick={() => console.log("Download Invoice")}>Download Excel <FaFileExcel /></Menu.Item>
          </Menu>
        }>
          <Button>Actions <DownOutlined /></Button>
        </Dropdown>
      )
    }
  ];

  return (
    <div className="container-right">
      <div className="filter mb-3 gap-2">
        <Select defaultValue="todays" onChange={(value) => setFilter({ ...filter, filter: value })}>
          <Option value="todays">Today's</Option>
          <Option value="Last3months">Last 3 months</Option>
          <Option value="Last6months">Last 6 months</Option>
          <Option value="Last12months">Last 12 months</Option>
          <Option value="Draft">Draft</Option>
        </Select>
        <Select defaultValue="no" onChange={(value) => setFilter({ ...filter, asc: value })}>
          <Option value="yes">Ascending</Option>
          <Option value="no">Descending</Option>
        </Select>
        <RangePicker className="range" onChange={(dates, dateStrings) => setFilter({ ...filter, fromDate: dateStrings[0], toDate: dateStrings[1] })} />
       
      </div>
      {loading ? <ShimmerTableN /> : (
        <Table
          columns={columns}
          dataSource={invoices}
          rowKey="_id"
          pagination={{
            current: currentPage,
            pageSize: invoicesPerPage,
            total: totalPages * invoicesPerPage,
            onChange: (page) => setCurrentPage(page),
          }}
        />
      )}
    </div>
  );
};

export default InvoiceList;
