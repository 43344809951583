import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import "../../assets/css/sidebar.css";
import Home from "../../assets/images/svg/home.svg";
import Cust from "../../assets/images/svg/cust.svg";
import Invoice from "../../assets/images/svg/invoices.svg";
import Inv from "../../assets/images/svg/invoicing.svg";
import Settings from "../../assets/images/svg/settings.svg";
import Noti from "../../assets/images/svg/notif.svg";
import Money from "../../assets/images/svg/money.svg";
import Bank from "../../assets/images/svg/bank.svg";

const Sidebar = ({ user }) => {
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState(localStorage.getItem("activeLink") || "dashboard");
  const [dashboardData, setDashboardData] = useState({});
  const userToken = localStorage.getItem("userToken");
  const [logos, setLogos] = useState();
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);

  useEffect(() => {
    if (!userToken) {
      navigate("/");
    } else {
      fetchInitialData();
      fetchLogos();
    }
  }, [userToken]);

  const fetchInitialData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_HOST}user`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) throw new Error("Failed to fetch dashboard data");

      const data = await response.json();
      setDashboardData(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload(); // Reload the page after logout
    setUserDropdownOpen(false); // Close the dropdown after logout
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
    localStorage.setItem("activeLink", link);
    setUserDropdownOpen(false); // Close the dropdown when a link is clicked
  };

  const getLinkName = () => {
    const item = menuItems.find((menu) => menu.key === activeLink);
    return item ? item.name : "";
  };

  const fetchLogos = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_HOST}alllogos`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (!response.ok) throw new Error("Failed to fetch logos");

      const data = await response.json();
      const lastLogoUrl = data.logos?.at(-1)?.url || null;
      setLogos(lastLogoUrl);
    } catch (error) {
      console.error("Error fetching logos:", error);
    }
  };

  const menuItems = [
    { name: "Home", path: "/dashboard", icon: Home, key: "dashboard" },
    { name: "Peoples", path: "/peoples", icon: Cust, key: "peoples" },
  ];

  const WorkFlow = [
    { name: "Purchase Order", path: "/purchaseorders", icon: Invoice, key: "purchaseorders" },
    { name: "Proposals", path: "/proposals", icon: Invoice, key: "proposals" },
    { name: "Challans", path: "/challans", icon: Invoice, key: "challans" }
  ];

  const CashFlow = [
    { name: "Invoicing", path: "/invoices", icon: Inv, key: "invoices" },
    { name: "Expenses", path: "/expenses", icon: Money,key: "expenses"},
    // { name: "Accounts", path: "/accounts", icon: Bank,key: "accounts"}
  ];

  const menuItems2 = [
    
    // { name: "Users & Roles", path: "/users", icon: Cust, key: "users" },
    { name: "Settings", path: "/settings", icon: Settings,key: "settings"}
  ];

  return (
    <>
      <div className="sidebar">
        <div className="sidebar-logo">
          <img src={Logo} alt="Logo" />
        </div>

       
        
        <div className="menu">
  {menuItems.map(({ name, path, icon, key }) => (
    <div key={key} className={`menu-items ${activeLink === key ? "active" : ""}`}>
      <Link to={path} onClick={() => handleLinkClick(key)}>
        <img src={icon} alt={name} />
        <p>{name}</p>
      </Link>
      {activeLink === key && key !== "dashboard" && (
        <Link to={`/create-${key}`}>
          <span id="activeIcon">+</span>
        </Link>
      )}

    
    </div>
  ))}


</div>




<div className="menu">
<span id="small">Cash flow</span>
  {CashFlow.map(({ name, path, icon, key }) => (
    <div key={key} className={`menu-items ${activeLink === key ? "active" : ""}`}>
      <Link to={path} onClick={() => handleLinkClick(key)}>
        <img src={icon} alt={name} />
        <p>{name}</p>
      </Link>
    </div>
  ))}
</div>


<div className="menu">
<span id="small">Work flow</span>
  {WorkFlow.map(({ name, path, icon, key }) => (
    <div key={key} className={`menu-items ${activeLink === key ? "active" : ""}`}>
      <Link to={path} onClick={() => handleLinkClick(key)}>
        <img src={icon} alt={name} />
        <p>{name}</p>
      </Link>
    </div>
  ))}

  
</div>

<div className="menu">

  {menuItems2.map(({ name, path, icon, key }) => (
    <div key={key} className={`menu-items ${activeLink === key ? "active" : ""}`}>
      <Link to={path} onClick={() => handleLinkClick(key)}>
        <img src={icon} alt={name} />
        <p>{name}</p>
      </Link>
      

    
    </div>
  ))}

  
</div>

      </div>

      {/* Top Bar */}
      <div className="sidebar-top">
        <div className="sidebar-top-left">
          <p>{getLinkName()}</p>
        </div>
        <div className="sidebar-top-right">
         
          {/* Notifications */}
          <Link to="">
            <img src={Noti} alt="Notifications" />
          </Link>
          {/* User Dropdown */}
          <div>
            {userDropdownOpen && (
              <div className="user-dropdown">
                <span id="admin">Admin</span>
                <span id="newline"/>
                <Link to=""> {dashboardData.fullName}<br />{dashboardData.email}</Link>
                <span id="newline"/>
                <Link to="/settings">Profile</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
                <span id="newline"/>
                <Link to="/terms-and-conditions" onClick={handleLogout}>Logout</Link>
              </div>
            )}
          </div>

          <div className="business-name">
            <p>{dashboardData.orgName || "Avrion"}</p>
            <span id="user" onClick={() => setUserDropdownOpen(!userDropdownOpen)}>
              {dashboardData.orgName ? dashboardData.orgName.slice(0, 2).toUpperCase() : "BN"}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
