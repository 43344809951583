import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import Tab from 'react-bootstrap/Tab';
import { message } from 'antd';

import Tabs from 'react-bootstrap/Tabs';
const CreateVendor = (prop) => {
  const navigate = useNavigate();

  const userToken = localStorage.getItem("userToken");
  const [key, setKey] = useState('personal');
  const [logoName, setLogoName] = useState('');
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: 0,
    userType: 'vendor',
    caId: '',
    orgName: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    telephone: '',
    nameOfContact: '',
    emailOfContact: '',
    phoneOfContact: '',
    currency: '',
    vatRegNo: '',
    pan: '',
    tin: '',
    gstNo: '',
    serviceTaxNo: '',
    tan: '',
    lstNo: '',
    cstNo: '',
    centralExciseNo: '',
    panURL: '',
    gstURL: '',
    chequeURL: '',
    msmeURL: '',
    division: '',
    eccNo: '',
    range: '',
    collectrate: '',
    vendorType: 'Importer',
    nameOfBank: '',
    acHolderName: '',
    bankBranch: '',
    addressBranch: '',
    telephoneBranch: 0,
    codeNoBankOrBranch: '',
    accountNo: '',
    ifsc: '',
    accountType: '',
    swiftCode: '',
    devlopmentAct: 'no',
    orgSize: 'Micro',
    ownerType: 'Proprietary',
    stateCode: 0,
    cinNumber: '',
    iecNumber: "",
    logoURL: '',
    logoType: ''
  });
  const [ca, setCa] = useState([]);
  const [fileInputs, setFileInputs] = useState({
    pan: '',
    gst: '',
    msme: '',
    cheque: '',
    logo: ''
  });

  const handleFileInputChange = (event) => {
    const { name, files } = event.target;
    setFileInputs({
      ...fileInputs,
      [name]: files[0],
    });
  };


  const uploadFiles = async (typ) => {

    let fileData = new FormData();
    switch (typ) {
      case "pan":
        fileData.append('mediaKey', fileInputs.pan); // Use correct keys for fileInputs
        break;

      case "gst":
        fileData.append('mediaKey', fileInputs.gst); // Use correct keys for fileInputs
        break;

      case "msme":
        fileData.append('mediaKey', fileInputs.msme); // Use correct keys for fileInputs
        break;

      case "cheque":
        fileData.append('mediaKey', fileInputs.cheque); // Use correct keys for fileInputs
        break;

      case "logo":
        fileData.append('mediaKey', fileInputs.logo); // Use correct keys for fileInputs
        break;
    }


    try {
      let response = await axios.post(`${process.env.REACT_APP_HOST}media-upload`, fileData, {
        headers: {
          'Authorization': `Bearer ${userToken}`,
          'Content-Type': 'multipart/form-data',
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      });
      switch (typ) {
        case "pan":
          setFormData({ ...formData, panURL: response.data.mediaKey }); // Use correct keys for fileInputs
          break;

        case "gst":
          setFormData({ ...formData, gstURL: response.data.mediaKey }); // Use correct keys for fileInputs
          break;
        case "msme":
          setFormData({ ...formData, msmeURL: response.data.mediaKey }); // Use correct keys for fileInputs
          break;


        case "cheque":
          setFormData({ ...formData, chequeURL: response.data.mediaKey }); // Use correct keys for fileInputs
          break;

        case "logo":
          uploadLogo(response.data.mediaKey);

          break;
      }

      message.success('Profile Updated Successfully');
    } catch (error) {
      console.error(error);
    }


  }





  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

  }

  const loadData = () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}/user`,
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    };

    axios.request(config)
      .then((response) => {
        setFormData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const loadCa = () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}allcas`,
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    };

    axios.request(config)
      .then((response) => {
        setCa(response.data.CAs);
      })
      .catch((error) => {
        console.log(error);
      });
  }


  const uploadLogo = (url) => {

    let data=JSON.stringify({url:`${process.env.REACT_APP_HOST}${url}`,logoName:logoName});
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}logo`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`
      },
      data:data
    };

    axios.request(config)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }





  useEffect(() => {
    loadData();
    loadCa();
  }, []);


  prop.setShow(true);
  const handleSubmit = (e) => {
    e.preventDefault();
    let data = JSON.stringify(formData);
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}/user`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        if (response.data.status) {
          message.success('Profile Updated Successfully');
         
        }

      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "warning",
          title: error.response.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      });

  };

  return (
    <div className="container-right">

      <form onSubmit={handleSubmit}>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="personal" title="Contact Information">
            <div className='biiling-inshights settings'>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Organization Name</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.orgName} type="text" className="form-control" name="orgName" />
                    </td>
                  </tr>

                  <tr>
                    <td>Email Address</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.email} type="email" className="form-control" name="email" disabled />
                    </td>
                  </tr>


                  <tr>
                    <td>Phone Number</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.phone} type="text" className="form-control" name="phone" />
                    </td>
                  </tr>

                  <tr>
                    <td>Address</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.address} type="text" className="form-control" name="address" />
                    </td>
                  </tr>
                  <tr>
                    <td>City</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.city} type="text" className="form-control" name="city" />
                    </td>
                  </tr>
                  <tr>
                    <td>State</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.state} type="text" className="form-control" name="state" />
                    </td>
                  </tr>
                  <tr>
                    <td>State Code</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.stateCode} type="text" className="form-control" name="stateCode" />
                    </td>
                  </tr>
                  <tr>
                    <td>Country</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.country} type="text" className="form-control" name="country" />
                    </td>
                  </tr>
                  <tr>
                    <td>Pin Code</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.zipCode} type="text" className="form-control" name="zipCode" />
                    </td>
                  </tr>







                </tbody>

              </table>
            </div>

          </Tab>
          <Tab eventKey="financial" title="Financial Information">
            <div className='biiling-inshights settings'>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Currency</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.currency} type="text" className="form-control" name="currency" />
                    </td>
                  </tr>
                  <tr>
                    <td>GST No.</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.gstNo} type="text" className="form-control" name="gstNo" />
                    </td>
                  </tr>

                  <tr>
                    <td>TAN</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.tan} type="text" className="form-control" name="tan" />
                    </td>
                  </tr>
                  <tr>
                    <td>PAN</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.pan} type="text" className="form-control" name="pan" />
                    </td>
                  </tr>
                </tbody>

              </table>
            </div>

          </Tab>
          <Tab eventKey="banking" title="Banking Details">
            <div className='biiling-inshights settings'>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Account Number</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.accountNo} type="number" className="form-control" name="accountNo" />
                    </td>
                  </tr>
                  <tr>
                    <td>Name of Bank</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.nameOfBank} type="text" className="form-control" name="nameOfBank" />
                    </td>
                  </tr>

                  <tr>
                    <td>A/C Holder Name</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.acHolderName} type="text" className="form-control" name="acHolderName" />
                    </td>
                  </tr>
                  <tr>
                    <td>Branch Name</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.bankBranch} type="text" className="form-control" name="bankBranch" />
                    </td>
                  </tr>
                  <tr>
                    <td>Address of Branch</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.addressBranch} type="text" className="form-control" name="addressBranch" />
                    </td>
                  </tr>
                  <tr>
                    <td>Branch Code</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.codeNoBankOrBranch} type="text" className="form-control" name="codeNoBankOrBranch" />
                    </td>
                  </tr>
                  <tr>
                    <td>IFSC</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.ifsc} type="text" className="form-control" name="ifsc" />
                    </td>
                  </tr>
                  <tr>
                    <td>Account Type</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.accountType} type="text" className="form-control" name="accountType" />
                    </td>
                  </tr>
                  <tr>
                    <td>Swift Code</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.swiftCode} type="text" className="form-control" name="swiftCode" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </Tab>
          {/* <Tab eventKey="legal" title="Legal Compliance">
            <div className='biiling-inshights settings'>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Terms and Conditions</td>
                    <td>
                      <textarea rows="10" cols="200" style={{ width: "700px" }}
                        onChange={handleOnChange} value={formData?.terms} type="text" className="form-control" name="terms" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>



          </Tab> */}
          <Tab eventKey="orgd" title="Organization Details">
            <div className='biiling-inshights settings'>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Organisation Type</td>
                    <td>
                      <select
                        onChange={handleOnChange} value={formData?.vendorType} type="text" className="form-control" name="vendorType">
                        <option value={'Private Limited'}>Private Limited</option>
                        <option value={'Government'}>Government</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Ownership Type</td>
                    <td>
                      <select
                        onChange={handleOnChange} value={formData?.ownerType} type="text" className="form-control" name="ownerType">
                        <option value={'Proprietary'}>Proprietary</option>
                        <option value={'Private Limited Company'}>Private Limited Company</option>
                        <option value={'Partnership'}>Partnership</option>
                        <option value={'Public Limited Company'}>Public Limited Company</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>CIN Number</td>
                    <td>

                      <input
                        onChange={handleOnChange} value={formData?.cinNumber} type="text" className="form-control" name="cinNumber" />
                    </td>
                  </tr>
                  <tr>
                    <td>IEC Number</td>
                    <td>
                      <input
                        onChange={handleOnChange} value={formData?.iecNumber} type="text" className="form-control" name="iecNumber" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Tab>

          <Tab eventKey="files" title="Documents Upload">
            <div className='biiling-inshights settings'>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>PAN</td>
                    <td>
                      <input onChange={handleFileInputChange} type="file" className="form-control" name="pan" />
                      <button type='button' onClick={() => uploadFiles("pan")}>{formData.panURL ? "Uploaded" : "Upload"}</button>
                    </td>
                  </tr>
                  <tr>
                    <td>GST</td>
                    <td>
                      <input onChange={handleFileInputChange} type="file" className="form-control" name="gst" />
                      <button type='button' onClick={() => uploadFiles("gst")}>{formData.gstURL ? "Uploaded" : "Upload"}</button>
                    </td>
                  </tr>
                  <tr>
                    <td>Canceled Cheque</td>
                    <td>
                      <input onChange={handleFileInputChange} type="file" className="form-control" name="cheque" />
                      <button type='button' onClick={() => uploadFiles("cheque")}>{formData.chequeURL ? "Uploaded" : "Upload"}</button>
                    </td>
                  </tr>
                  <tr>
                    <td>Company Logo</td>
                    <td>
                      <input onChange={handleFileInputChange} type="file" className="form-control" name="logo" />
                      <label>Logo Name:<input onChange={(e)=>setLogoName(e.target.value)} type="text" className="form-control" name="logoName" /></label>
                      <button disabled={!logoName} type='button' onClick={() => uploadFiles("logo")}>{"Upload"}</button>
                    </td>
                  </tr>
                  <tr>
                    <td>MSME Cerificate</td>
                    <td>
                      <input onChange={handleFileInputChange} type="file" className="form-control" name="msme" />
                      <button type='button' onClick={() => uploadFiles("msme")}>{formData.msmeURL ? "Uploaded" : "Upload"}</button>
                    </td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
          </Tab>
          <Tab eventKey="documents" title="Documents View">
            <div className='biiling-inshights settings'>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>PAN</td>
                    <td>
                      {formData.panURL ? (
                        <a href={formData.panURL} target="_blank" rel="noopener noreferrer">View PAN</a>
                      ) : (
                        <span>No PAN Uploaded</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>GST</td>
                    <td>
                      {formData.gstURL ? (
                        <a href={formData.gstURL} target="_blank" rel="noopener noreferrer">View GST</a>
                      ) : (
                        <span>No GST Uploaded</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>MSME Certificate</td>
                    <td>
                      {formData.msmeURL ? (
                        <a href={formData.msmeURL} target="_blank" rel="noopener noreferrer">View MSME Certificate</a>
                      ) : (
                        <span>No MSME Certificate Uploaded</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Canceled Cheque</td>
                    <td>
                      {formData.chequeURL ? (
                        <a href={formData.chequeURL} target="_blank" rel="noopener noreferrer">View Canceled Cheque</a>
                      ) : (
                        <span>No Canceled Cheque Uploaded</span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Tab>
       
        </Tabs>


        <div className='text-center m-5 btn'>


          <button className='btn btn-primary' type='submit' >Save</button>
        </div>







      </form>

    </div>
  )
};

export default CreateVendor;