import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { message, Input, Button, Spin } from "antd";
import Logo from "../../assets/images/logo.png";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [userToken, setUserToken] = useState(localStorage.getItem("userToken"));
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (userToken) {
      navigate("/dashboard");
    }
  }, [userToken, navigate]);

  const sendOtp = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
       `${process.env.REACT_APP_HOST}send-otp`,
        { email }
      );
      
      if (response.data) {
        message.success("OTP sent successfully", 1.5);
        setOtpSent(true);
      } else {
        message.warning(response.data.message || "Failed to send OTP", 1.5);
      }
    } catch (error) {
      console.error(error);
      message.error("Something went wrong, please try again", 1.5);
    }
    setLoading(false);
  };



  const handleOtpSubmit = async () => {
    console.log(otp)
    let data = JSON.stringify({
      "email": email,
      "otp": String(otp)
    });
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}verify-otp`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      if (response.data?.status) {
        localStorage.setItem("userToken", response.data.userToken);
        setUserToken(response.data.userToken);
        message.success("OTP Verified Successfully", 1.5);
        navigate("/dashboard");
      } else {
        message.warning("Invalid OTP, please try again", 1.5);
      }
    })
    .catch((error) => {
      message.error(
        error.response?.data?.message || "Something went wrong, please try again",
        1.5
      );
    });
  };
  
  
  return (
    <>
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <div className="login-box">
          <div className="login-heading">
            <img src={Logo} alt="logo" className="logo" width={200} />
            <p>Accounting & Cashflow Manager!</p>
          </div>
          <form>
            <div className="textinput">
              <label>Email Address</label>
              <input
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Email Address"
                value={email}
                type="email"
                disabled={otpSent}
              />
            </div>

            {!otpSent ? (
              <div className="inputBtn">
                <Button onClick={sendOtp} type="primary" disabled={loading}>
                  {loading ? <Spin size="small" /> : "Send OTP"}
                </Button>
              </div>
            ) : (
              <>
                <div className="textinput">
                <label>Enter OTP</label>
                <Input.OTP variant="filled" value={otp} onChange={setOtp} length={6} />
                {otpSent && (
  <span onClick={sendOtp} id="resend">Resend</span>
)}

            </div>
               
              
                <div className="inputBtn">
                  <Button onClick={handleOtpSubmit} type="primary"  disabled={loading}> {loading ? <Spin size="small" /> : "Verify OTP"}</Button>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
