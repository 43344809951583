import React, { useEffect, useState } from "react";
import { Table, Dropdown, Menu, Button, Pagination, message } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ShimmerTable } from "react-shimmer-effects";
import Swal from "sweetalert2";

const PeopleList = ({ setShow }) => {
  useEffect(() => {
    setShow(true);
  }, []);

  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const userToken = localStorage.getItem("userToken");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const loadVendors = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_HOST}customersmine`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      setVendors(data.customers || []);
      setTotalPages(Math.ceil((data.customers?.length || 0) / 10));
    } catch (error) {
     
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadVendors();
  }, []);

  const handleDelete = async (vendorId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_HOST}customer-delete/${vendorId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      Swal.fire({ icon: "success", title: "Vendor Deleted Successfully", timer: 1500 });
      loadVendors();
    } catch (error) {
      message.error("Failed to delete vendor");
    }
  };

  const columns = [
    { title: "Company Name", dataIndex: "orgName" },
    { title: "Email", dataIndex: "email" },
    { title: "Total Billing", dataIndex: "totalBilling" },
    { title: "Status", dataIndex: "clientStatus",
      render: (clientCycle) => (
        <span id={clientCycle?.toLowerCase()}>{clientCycle}</span>
      ), },
    { title: "Recurring", dataIndex: "clientCycle" },
    {
      title: "Actions",
      render: (_, record) => (
        <Dropdown overlay={
          <Menu>
            <Menu.Item onClick={() => navigate(`/customer/edit/${record._id}`)}>View/Edit</Menu.Item>
            <Menu.Item onClick={() => handleDelete(record._id)}>Delete</Menu.Item>
          </Menu>
        }>
           <Button>Actions <DownOutlined /></Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="container-right">
      {loading ? (
        <ShimmerTable row={5} col={5} />
      ) : (
        <Table
          columns={columns}
          dataSource={vendors}
          rowKey="_id"
          pagination={{
            current: currentPage,
            pageSize: 10,
            total: totalPages * 10,
            onChange: (page) => setCurrentPage(page),
          }}
        />
      )}
    </div>
  );
};

export default PeopleList;
