import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Table, Dropdown, Button, Select, Pagination, Spin, message,Empty} from "antd";
import { DownOutlined, DownloadOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import InvoiceNumberComponent from "../Format";
import { ShimmerTable } from "react-shimmer-effects";
const Dashboard = ({ setShow, user }) => {
  setShow(true);
  const navigate = useNavigate();
  const userToken = localStorage.getItem("userToken");

  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({ filter: "todays", asc: "no" });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const invoicesPerPage = 10;

  useEffect(() => {
    fetchInvoices();
  }, [filter, currentPage]);

  const fetchInvoices = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST}filterpurchaseorders`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
          params: { ...filter, page: currentPage, limit: invoicesPerPage },
        }
      );
      setInvoices(response.data.purchaseOrders || []);
      setTotalPages(Math.ceil(response.data.total / invoicesPerPage));
    } catch (error) {
    
    }
    setLoading(false);
  };

  const handleDelete = async (invoiceId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_HOST}purchaseorder/${invoiceId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      setInvoices((prev) => prev.filter((invoice) => invoice._id !== invoiceId));
      message.success("Purchase Order Deleted Successfully");
    } catch (error) {
      message.warning(error.response?.data?.message || "An error occurred");
    }
  };

  const handleView = (invoiceId) => {
    window.open(`/purchaseorder/${invoiceId}/view`, "_blank");
  };

  const columns = [
    { title: "Date", dataIndex: "createdDate", render: (date) => moment(date).format("DD-MM-YYYY") },
    { title: "Order#", dataIndex: "billNumber", render: (billNumber) => <InvoiceNumberComponent page="PO" billNumber={billNumber} /> },
    { title: "Organisation Name", dataIndex: "vendorName" },
    { title: "Status", dataIndex: "poStatus" ,render: (clientCycle) => (
      <span id={clientCycle?.toLowerCase()}>{clientCycle}</span>
    ),},
    { title: "Amount", dataIndex: "totalINR", render: (amount) => amount.toFixed(0) },
    {
      title: "Manage",
      render: (record) => (
        <Dropdown
          menu={{
            items: [
              { key: "view", label: "View", icon: <DownloadOutlined />, onClick: () => handleView(record._id) },
              { key: "edit", label: "Edit", icon: <EditOutlined />, onClick: () => navigate(`/po/edit/${record._id}`) },
              { key: "delete", label: "Delete", icon: <DeleteOutlined />, onClick: () => handleDelete(record._id) },
            ],
          }}
        >
           <Button>Actions <DownOutlined/></Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="container-right">
     
        <div className="filter d-flex gap-2">
          <Select
            value={filter.filter}
            onChange={(value) => setFilter({ ...filter, filter: value })}
            options={[
              { value: "todays", label: "Today's" },
              { value: "Last3months", label: "Last 3 months" },
              { value: "Last6months", label: "Last 6 months" },
              { value: "Last12months", label: "Last 12 months" },
              { value: "Draft", label: "Draft" },
            ]}
          />
          <Select
            value={filter.asc}
            onChange={(value) => setFilter({ ...filter, asc: value })}
            options={[{ value: "yes", label: "Ascending" }, { value: "no", label: "Descending" }]}
          />
      
        </div>
        <div className="d-flex justify-content-end mt-2">
          <Button type="primary" onClick={() => navigate("/create-purchaseorder")}>+ New</Button>
        </div>
        {loading ? (
           <ShimmerTable row={5} col={6} />
        ) : invoices.length === 0 ? (
          <div className="no-data-message text-center">
          <Empty description="No Data Found" />
        </div>
        ) : (
          <>
            <Table columns={columns} dataSource={invoices} 
            pagination={{
              current: currentPage,
              pageSize: invoicesPerPage,
              total: totalPages * invoicesPerPage,
              onChange: (page) => setCurrentPage(page),
            }}
            />
          </>
        )}
      
    </div>
  );
};

export default Dashboard;
