import React, { useState, useEffect } from 'react';

import '../../../assets/css/proposal.css';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import InvoiceNumberComponent from '../Format';
import moment from 'moment';
import { ToWords } from 'to-words';


const tableStyle = {
    borderCollapse: 'collapse',
    width: '97%',
    marginRight: 'auto',
    

};

const thTdStyle = {
    border: '1px solid black',
    padding: '5px',
    textAlign: 'left',
};

function ProposalDetails() {
    const toWords = new ToWords();

    useEffect(() => {
        // Log the content height when the component mounts
        const printContainer = document.querySelector('.print-container');
        console.log('Print Container Height:', printContainer.offsetHeight);
    }, []);

    const handlePrint = () => {
        // Log the content height before printing
        const printContainer = document.querySelector('.print-container');
        console.log('Print Container Height (before printing):', printContainer.offsetHeight);

        // Trigger printing
        window.print();
    };

    const proposalId = useParams();
    const userToken = localStorage.getItem("userToken");
    const [proposal, setProposal] = useState({});


    const loadInvoice = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_HOST}proposal/${proposalId.proposalId}`,
            headers: {
                'Authorization': `Bearer ${userToken}`
            }
        };

        axios.request(config)
            .then((response) => {
                setProposal(response.data.proposal);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        loadInvoice();
    }, []);

    return (
        <div className="parent App prop">

            <div className="print-container App-container" >
                <div className="App-container-one">

                </div>
                <div className="App-container-two">


                    <div className="App-container-two-big">

                        <div className="App-container-two-left">
                            <div className="App-box-left">
                                <img style={{ width: "400px" }} src={proposal?.logoURL} alt="" />
                            </div>
                            <div className="App-box-right">

                                <h3 style={{ fontSize: "16px", color: "#000 ", marginTop: "30px", fontWeight: 800 }}>{proposal?.orgName}  </h3>
                                <hr />
                                <h4> ATTN: Name / Dept - {proposal?.customName}</h4>
                                <h4> Address : {proposal?.customAddress}  </h4>
                                <h4> Contact number : {proposal?.customPhone}</h4>
                                {/* <h4> Email Address : {proposal?.email}</h4> */}



                            </div>



                        </div>
                        <div className='right-pro'>


                            <h3 style={{ fontSize: '16px',textAlign:'end',marginTop:'20px' }} >QUOTE NO. <strong><InvoiceNumberComponent page={'QT'} billNumber={proposal.billNumber} /></strong> </h3>
                            <div className="App-container-two-right">


                                <hr />

                                <h3 >DATE: {moment(proposal.createdDate).format('DD-MM-YYYY')} </h3>

                                <hr />
                                <h3 > CUSTOMER ID: {proposal?.customId}</h3>
                                <hr />
                                <h3 > VALID UNTIL: {moment(proposal.vaildity).format('DD-MM-YYYY')} </h3>
                                <hr />



                            </div>
                        </div>


                    </div>



                    <div className="Table-container-five">
                        <table style={{ borderCollapse: 'collapse', width: '97%', marginLeft: '', border: "none" }} >
                            <colgroup>
                                <col style={{ width: '5%' }} />
                                <col style={{ width: '59%' }} />
                                <col style={{ width: '10%' }} />
                                <col style={{ width: '5%' }} />
                                <col style={{ width: '5%' }} />
                                <col style={{ width: '12%' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th style={{ color: "black", border: '1px solid black', fontSize: '10px !important' }}>S.N</th>
                                    <th style={{ color: "black", border: '1px solid black', fontSize: '10px !important' }}>Description of Goods</th>
                                    <th style={{ color: "black", border: '1px solid black', fontSize: '10px !important' }}>UOM</th>
                                    <th style={{ color: "black", border: '1px solid black', fontSize: '10px !important' }}>HSN</th>
                                    <th style={{ color: "black", border: '1px solid black', fontSize: '10px !important' }}>QTY</th>
                                    <th style={{ color: "black", border: '1px solid black', fontSize: '10px !important' }}>Unit Price</th>
                                    <th style={{ color: "black", border: '1px solid black', fontSize: '10px !important' }}>Total Amount</th>
                                </tr>
                            </thead>
                            <tbody>

                                {proposal?.productDesc?.map((p, index) => (
                                    <tr key={index}>
                                        <td style={{ border: '1px solid black' }}>{index + 1}</td>
                                        <td style={{ border: '1px solid black' }}>{p.name}</td>
                                        <td style={{ border: '1px solid black' }}>{p.uom}</td>
                                        <td style={{ border: '1px solid black' }}>{p.hsn}</td>
                                        <td style={{ border: '1px solid black' }}>{p.qty}</td>
                                        <td style={{ border: '1px solid black' }}>{parseFloat(p.price)?.toFixed(0)}</td>
                                        <td style={{ border: '1px solid black' }}>{(p.qty * p.price)?.toFixed(0)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>


                    </div>


                    <div className='dynmaic'>

                        <div className="last-container prop" style={{ marginTop: "50px !important" }}>
                            <div className="last-container-left">



                                <h6 id="proposalwords">Total Amount in words: <strong>{toWords.convert(proposal?.totalINR?.toFixed(0) || 0)} Rupees only</strong></h6>


                            </div>
                            <div className="last-container-right">
                                <div className="Table-container-six tax">
                                    <table style={{ ...tableStyle, width: '98%' }} className='purchasetable'>
                                        <tbody style={{fontSize:'25px !important'}}>
                                            <tr>
                                                <th style={thTdStyle}>Subtotal</th>
                                                <td style={thTdStyle}>{proposal?.subTotal?.toFixed(0)}</td>
                                            </tr>

                                            {/*<tr>
                                                <th style={thTdStyle}>{proposal?.igst ? 'Tax Rate' : 'GST'}</th>
                                                <td style={thTdStyle}>{'18%'}</td>
                            </tr>*/}
                                            {proposal?.taxType === 'igst' ? (<tr>
                                                <th style={{...thTdStyle,minWidth:'100px'}}>IGST 18%</th>
                                                <td style={thTdStyle}>{proposal?.igst?.toFixed(0)}</td>
                                            </tr>) : (
                                                <>
                                                    <tr>
                                                        <th style={thTdStyle}>SGST 9%</th>
                                                        <td style={thTdStyle}>{proposal?.sgst?.toFixed(0)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={thTdStyle}>CGST 9%</th>
                                                        <td style={thTdStyle}>{proposal?.cgst?.toFixed(0)}</td>
                                                    </tr>

                                                </>)}
                                            <tr>
                                                <th style={thTdStyle}>Total Tax</th>
                                                <td style={thTdStyle}>{proposal?.totalTax?.toFixed(0)}</td>
                                            </tr>
                                            {/* <tr>
                                                <th style={thTdStyle}>Shipping/Handling Charges</th>
                                                <td style={thTdStyle}>{proposal?.shipOrHandCharge || 0}</td>
                                            </tr> */}
                                            <tr>
                                                <th style={thTdStyle}><h6 style={{ fontWeight: 'bolder', fontSize: '18px' }}>TOTAL</h6></th>
                                                <td style={thTdStyle}><h6 style={{ fontWeight: 'bolder', fontSize: '18px' }}>{proposal?.totalINR?.toFixed(0)}</h6></td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>

                            </div>
                        </div>
                        {proposal?.terms?.[0] !== '' &&
                            <p id="p">  Terms and Conditions: <br />
                                {
                                    proposal?.terms?.map((t, index) => (
                                        <p>{`${index + 1}. ${t}`}</p>
                                    ))
                                }
                            </p>}


                    </div>
                    <p id="bottom">
                        The Purchase/Work order terms issued on respective proposal number or quotation number does not supersede the quotation terms <br /><br />Banking details: as mentioned in the Vendor Registration Form Offered price and terms conditions are subjected to change as per issuers will without notice and receiver may be intimated through an official mall it is recommended to opt for fresh quotation for issuing fresh Work order
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ProposalDetails;


