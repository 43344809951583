import React, { useEffect, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { DropdownButton, Dropdown, Pagination } from "react-bootstrap";
import Swal from "sweetalert2";
import { CiShare1 } from "react-icons/ci";
import { ShimmerTable } from "react-shimmer-effects";

const PeopleList = (prop) => {
  useEffect(() => {
    prop.setShow(true);
  }, []);

  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const userToken = localStorage.getItem("userToken");
  const [showVendorDetail, setShowVendorDetail] = useState(false);
  const [dashboardData, setDashboardData] = useState({});
  const [filter, setFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [invoicesPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const loadVendors = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}customersmine`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setVendors(response.data.customers);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_HOST}user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setDashboardData(data.data);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchDashboardData();
  }, []);

  const navigate = useNavigate();
  const handleAddPeople = () => {
    navigate("/create-peoples");
  };

  const handleCreate = () => {
    navigate(`/vendor-form/${dashboardData._id}/${dashboardData.fullName}`);
  };

  useEffect(() => {
    loadVendors();
  }, []);

  const handleDelete = (vendorId) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}customer-delete/${vendorId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.status) {
          window.location.reload();
          Swal.fire({
            icon: "success",
            title: "Vendor Deleted Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "warning",
          title: error.response.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const handleFilter = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_HOST}filteredcustomers?filter=${filter}`,
        config
      );
      setVendors(response.data.customers);
      const totalInvoices = response.data.customers.length || 0;
      setTotalPages(Math.ceil(totalInvoices / invoicesPerPage));
    } catch (error) {
      setVendors([]);
    }
  };

  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pages;
  };

  return (
    <div className="container-right">
      <div className="container mt-5 mt-0 d-flex flex-column">
        <div className="filter">
          <select onChange={(e) => setFilter(e.target.value)}>
            <option value={"todays"}>Today's</option>
            <option value={"Last3months"}>Last 3 months</option>
            <option value={"Last6months"}>Last 6 months</option>
            <option value={"Last12months"}>Last 12 months</option>
            <option value={"hold"}>Hold</option>
            <option value={"active"}>Active</option>
          </select>
          <button type="button" onClick={handleFilter}>
            Search
          </button>
        </div>
        <div className="d-flex justify-content-end">
          <button className="btn btn-dark new" onClick={handleAddPeople}>
            + New
          </button>
          <button
            className="btn btn-dark new"
            id="share"
            onClick={handleCreate}
          >
            <CiShare1 /> Share with vendor
          </button>
        </div>
        {loading ? (
          <ShimmerTable />
        ) : (
          <>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col"></th>
                
                  <th scope="col">Company Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Total Billing</th>
                  <th scope="col"> Status</th>
               
                  <th scope="col">Recurring</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {vendors
                  ?.slice(
                    (currentPage - 1) * invoicesPerPage,
                    currentPage * invoicesPerPage
                  )
                  ?.map((vendor, index) => (
                    <tr
                      className={
                        index % 2 === 0 ? "bg-light" : "bg-secondary text-black"
                      }
                    >
                      <td>
                        <input type="checkbox" />
                      </td>
                   
                    
                      <td>{vendor.orgName}</td>
                    
                      <td>{vendor.email}</td>
                      <td>{vendor.totalBilling}</td>
                      <td>
                        <span id={vendor.clientStatus}>
                          {vendor.clientStatus}
                        </span>
                      </td>
                   
                      <td>{vendor.clientCycle}</td>
                      <td>
                        <DropdownButton
                          id="dropdown-item-button btn-dark"
                          variant="dark"
                          title={<BsThreeDots size={26} />}
                        >
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              navigate(`/customer/edit/${vendor._id}`)
                            }
                          >
                            View/Edit Customer
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() => handleDelete(vendor._id)}
                          >
                            Delete
                          </Dropdown.Item>
                        </DropdownButton>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <Pagination>
              <Pagination.Prev
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
              />
              {renderPagination()}
              <Pagination.Next
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </>
        )}
      </div>
    </div>
  );
};

export default PeopleList;
