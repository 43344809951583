import { useEffect, useState } from "react";
import { Table, Dropdown, Menu, Button, Select, Empty, message, Pagination } from "antd";
import { DownOutlined, DownloadOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";


import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import { ShimmerTable } from "react-shimmer-effects";
import FO from "../../../assets/images/fo.png";

const { Option } = Select;

const ProposalList = ({ setShow, user }) => {
  setShow(true);
  const navigate = useNavigate();
  const userToken = localStorage.getItem("userToken");

  const [proposals, setProposals] = useState([]);
  const [filter, setFilter] = useState({ filter: "", asc: "no" });
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [proposalsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchProposals();
  }, [filter, currentPage]);

  const fetchProposals = async () => {
    setLoading(true);
    try {
      const config = { headers: { Authorization: `Bearer ${userToken}` } };
      const { data } = await axios.get(
        `${process.env.REACT_APP_HOST}filterproposals?filter=${filter.filter}&asc=${filter.asc}&page=${currentPage}&limit=${proposalsPerPage}`,
        config
      );
      setProposals(data.proposals || []);
      setTotalPages(Math.ceil(data.total / proposalsPerPage));
    } catch (error) {
    
      setProposals([]);
    }
    setLoading(false);
  };

  const handleDelete = async (id) => {
    try {
      const config = { headers: { Authorization: `Bearer ${userToken}` } };
      await axios.delete(`${process.env.REACT_APP_HOST}proposal/${id}`, config);
      Swal.fire({ icon: "success", title: "Proposal Deleted Successfully", timer: 1500 });
      fetchProposals();
    } catch (error) {
      message.error("Failed to delete proposal");
    }
  };

  const columns = [
    { title: "Date", dataIndex: "createdDate", render: (date) => moment(date).format("DD-MM-YYYY") },
    { title: "Proposal #", dataIndex: "billNumber" },
    { title: "Organisation Name", dataIndex: "orgName" },
    { title: "Status", dataIndex: "proposalStatus" ,render: (clientCycle) => (
      <span id={clientCycle?.toLowerCase()}>{clientCycle}</span>
    ),},
    { title: "Amount", dataIndex: "totalINR", render: (amount) => `₹ ${amount.toFixed(2)}` },
    {
      title: "Manage",
      render: (_, record) => (
        <Dropdown overlay={
          <Menu>
            <Menu.Item onClick={() => navigate(`/proposal/${record._id}/view`)}><DownloadOutlined /> View</Menu.Item>
            <Menu.Item onClick={() => navigate(`/proposal/edit/${record._id}`)}><EditOutlined /> Edit</Menu.Item>
            <Menu.Item onClick={() => navigate(`/create-invoice/${record._id}`)}>Convert to Invoice</Menu.Item>
            <Menu.Item onClick={() => handleDelete(record._id)}><DeleteOutlined /> Delete</Menu.Item>
          </Menu>
        }>
          <Button>Actions <DownOutlined/></Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="container-right">
      <div className="filter mb-3 d-flex gap-2">
        <Select defaultValue="todays" onChange={(value) => setFilter({ ...filter, filter: value })}>
          <Option value="todays">Today's</Option>
          <Option value="Last3months">Last 3 months</Option>
          <Option value="Last6months">Last 6 months</Option>
          <Option value="Last12months">Last 12 months</Option>
          <Option value="Draft">Draft</Option>
        </Select>
        <Select defaultValue="no" onChange={(value) => setFilter({ ...filter, asc: value })}>
          <Option value="yes">Ascending</Option>
          <Option value="no">Descending</Option>
        </Select>
       
      </div>
      <Button type="primary" className="mb-3" onClick={() => navigate("/create-proposal")}>+ New</Button>
      {loading ? (
        <ShimmerTable row={5} col={6} />
      ) : proposals.length === 0 ? (
        <div className="no-data-message text-center">
          <Empty description="No Data Found" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={proposals}
          rowKey="_id"
          pagination={{
            current: currentPage,
            pageSize: proposalsPerPage,
            total: totalPages * proposalsPerPage,
            onChange: (page) => setCurrentPage(page),
          }}
        />
      )}
    </div>
  );
};

export default ProposalList;
