import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Table, Dropdown, Button, Select, Pagination, message, Empty } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import moment from "moment";
import InvoiceNumberComponent from "../Format";
import { DownOutlined, DownloadOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

const ChallanList = ({ setShow }) => {
  const userToken = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const [invoices, setInvoices] = useState([]);
  const [filter, setFilter] = useState({ filter: "todays", asc: "no" });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const invoicesPerPage = 10;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setShow(true);
  }, [setShow]);

  const fetchInvoices = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://billapi.multigrad.in/auth/v1/filterchallans`, {
        headers: { Authorization: `Bearer ${userToken}` },
        params: { ...filter, page: currentPage, limit: invoicesPerPage },
      });

      setInvoices(response.data.challans || []);
      setTotalPages(Math.ceil((response.data.total || 0) / invoicesPerPage));
    } catch (error) {
     
      setInvoices([]);
      setTotalPages(1);
    } finally {
      setLoading(false);
    }
  }, [filter, currentPage, userToken]);

  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices]);

  const handleDelete = async (invoiceId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_HOST}/challan/${invoiceId}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      message.success("Challan deleted successfully");
      fetchInvoices();
    } catch (error) {
      message.error(error.response?.data?.message || "Error deleting challan");
    }
  };

  const handleView = (invoiceId) => window.open(`/challan/${invoiceId}/view`, "_blank");

  const columns = [
    { title: "Date", dataIndex: "createdDate", render: (date) => moment(date).format("DD-MM-YYYY") },
    { title: "Challan#", dataIndex: "billNumber", render: (num) => <InvoiceNumberComponent page="CH" billNumber={num} /> },
    { title: "Organisation Name", dataIndex: "customName" },
    { title: "Status", dataIndex: "challanStatus",render: (clientCycle) => (
      <span id={clientCycle?.toLowerCase()}>{clientCycle}</span>
    ), },
    {
      title: "Manage",
      render: (_, record) => (
        <Dropdown
          menu={{
            items: [
              { key: "view", label: "View", icon: <DownloadOutlined />, onClick: () => handleView(record._id) },
              { key: "edit", label: "Edit",icon: <EditOutlined />, onClick: () => navigate(`/challan/edit/${record._id}`) },
              { key: "delete", label: "Delete",icon: <DeleteOutlined />, onClick: () => handleDelete(record._id) },
            ],
          }}
        >
          <Button>Actions <DownOutlined /></Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="container-right">
      <div className="filter d-flex gap-2 mb-3">
        <Select
          defaultValue="todays"
          onChange={(value) => setFilter((prev) => ({ ...prev, filter: value }))}
          options={[
            { value: "todays", label: "Today's" },
            { value: "Last3months", label: "Last 3 months" },
            { value: "Last6months", label: "Last 6 months" },
            { value: "Last12months", label: "Last 12 months" },
            { value: "Draft", label: "Draft" },
          ]}
        />
        <Select
          defaultValue="no"
          onChange={(value) => setFilter((prev) => ({ ...prev, asc: value }))}
          options={[
            { value: "yes", label: "Ascending" },
            { value: "no", label: "Descending" },
          ]}
        />
      
      </div>
      <Button type="primary" onClick={() => navigate("/create-challan")} className="mb-3">
        + New
      </Button>
      
      {loading ? (
        <div className="text-center">Loading...</div>
      ) : invoices.length === 0 ? (
        <div className="no-data-message text-center">
          <Empty description="No Data Found" />
        </div>
      ) : (
        <>
          <Table
            columns={columns}
            dataSource={invoices}
            rowKey="_id"
            pagination={{
              current: currentPage,
              pageSize: invoicesPerPage,
              total: totalPages * invoicesPerPage,
              onChange: (page) => setCurrentPage(page),
            }}
          />
         
        </>
      )}
    </div>
  );
};

export default ChallanList;
