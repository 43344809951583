import { useState, useEffect } from "react";
import { Modal, Button, Form, Input, Select, DatePicker, Table, Dropdown, Menu, message } from "antd";
import { DownOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import { ShimmerTable } from "react-shimmer-effects";

const { Option } = Select;

const Dashboard = ({ setShow }) => {
  setShow(true);

  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [currentExpense, setCurrentExpense] = useState(null);
  const [customDate, setCustomDate] = useState(null);
  const [form] = Form.useForm();

  const userToken = localStorage.getItem("userToken");

  const fetchExpenses = async () => {
    try {
      const { data } = await axios.get("https://rlbill.rapydlaunch.com/auth/v1/myexpenses", {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      setExpenses(data.expenses || []);
    } catch (error) {
      console.error("Error fetching expenses:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExpenses();
  }, []);

  const handleAddExpense = async (values) => {
    try {
      const expenseData = { ...values };
      if (values.recurring === "custom") {
        expenseData.customDate = customDate;
      }
      await axios.post("https://rlbill.rapydlaunch.com/auth/v1/expense", expenseData, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      message.success("Expense added successfully");
      setModalVisible(false);
      fetchExpenses();
    } catch (error) {
      console.error("Error adding expense:", error);
      message.error("Failed to add expense");
    }
  };

  const handleEditExpense = async (values) => {
    try {
      await axios.patch(`https://rlbill.rapydlaunch.com/auth/v1/expense/${currentExpense._id}`, values, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      message.success("Expense updated successfully");
      setEditModalVisible(false);
      fetchExpenses();
    } catch (error) {
      console.error("Error updating expense:", error);
      message.error("Failed to update expense");
    }
  };

  const handleDeleteExpense = async (id) => {
    try {
      await axios.delete(`https://rlbill.rapydlaunch.com/auth/v1/expense/${id}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      message.success("Expense deleted successfully");
      fetchExpenses();
    } catch (error) {
      console.error("Error deleting expense:", error);
      message.error("Failed to delete expense");
    }
  };

  return (
    <div className="container-right">
      <Button type="primary" onClick={() => setModalVisible(true)}>Add Expense</Button>
      {loading ? <ShimmerTable row={5} col={5} /> : <Table columns={[
        { title: "Date", dataIndex: "createdDate", render: (text) => moment(text).format("DD-MM-YYYY") },
        { title: "Name", dataIndex: "name" },
        { title: "Category", dataIndex: "type" },
        { title: "Amount", dataIndex: "amount", render: (text) => `₹ ${text}` },
        { title: "Recurring", dataIndex: "recurring" },
        { title: "Actions", render: (_, record) => (
            <Dropdown overlay={
              <Menu>
                <Menu.Item key="edit" onClick={() => { setCurrentExpense(record); setEditModalVisible(true); }}>Edit</Menu.Item>
                <Menu.Item key="delete" danger onClick={() => handleDeleteExpense(record._id)}>Delete</Menu.Item>
              </Menu>
            }>
              <Button>Actions <DownOutlined /></Button>
            </Dropdown>
          ) },
      ]} dataSource={expenses} rowKey="_id" pagination={{ pageSize: 10 }} />}

      <Modal title="Add Expense" visible={modalVisible} onCancel={() => setModalVisible(false)} onOk={() => form.submit()}>
        <Form form={form} onFinish={handleAddExpense} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true }]}><Input /></Form.Item>
          <Form.Item name="type" label="Category" rules={[{ required: true }]}><Input /></Form.Item>
          <Form.Item name="amount" label="Amount" rules={[{ required: true }]}><Input type="number" /></Form.Item>
          <Form.Item name="recurring" label="Recurring" rules={[{ required: true }]}>
            <Select onChange={(value) => setCustomDate(value === "custom" ? moment() : null)}>
              <Option value="onetime">One-time</Option>
              <Option value="monthly">Monthly</Option>
              <Option value="yearly">Yearly</Option>
              <Option value="3 months">3 Months</Option>
              <Option value="6 months">6 Months</Option>
              <Option value="custom">Custom</Option>
            </Select>
          </Form.Item>
          {customDate && <Form.Item name="customDate" label="Custom Date" rules={[{ required: true }]}>
            <DatePicker onChange={(date) => setCustomDate(date)} format="DD-MM-YYYY" />
          </Form.Item>}
        </Form>
      </Modal>
    </div>
  );
};

export default Dashboard;